/* eslint-disable @typescript-eslint/naming-convention */
import {AfterViewInit, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Attendee} from '../../services/Meetings/attendee.interface';
import {MeetingService} from '../../services/Meetings/MeetingService';
import {RecommendedAttendee} from '../../services/Meetings/recommended-attendee.interface';
import {Contacts} from '@capacitor-community/contacts';
import {ModalController} from '@ionic/angular';
import {ContactListModalComponent} from '../../modals/contact-list/contact-list-modal.component';
import Bugsnag from '@bugsnag/js';
import {CalendarService} from '../../services/Calendars/CalendarService';
import {ModuleService} from '../../services/Modules/ModuleService';
import {ToastService} from '../../services/General/ToastService';
import {AndroidSettings, IOSSettings, NativeSettings} from 'capacitor-native-settings';

@Component({
  selector: 'app-invite-attendees',
  templateUrl: './invite-attendees.component.html',
  styleUrls: ['./invite-attendees.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class InviteAttendeesComponent implements AfterViewInit {
  @Input() attendees: Array<Attendee>;
  @Input() host = false;
  @Input() isNative = false;
  @Output() attendeesChange = new EventEmitter<Array<Attendee>>();
  @Input() meetingId: number;
  @Input() nativeEventId: string = null;
  @Input() customerName: string;
  @Input() allowCarParkReservation: boolean;
  @Input() allowCarParkWithInvitation: boolean;
  addEmails: any = [{email: '', parking: 0, invite_as_host: false}];
  existingEmails = [];
  recommended: Array<RecommendedAttendee>;
  disableBtn = false;
  modal: any;
  enabledContacts = true;
  openParkingModal?: number;
  selectedParkingSetting = 1;
  openOrganizerModal?: number;
  selectedHostSetting = false;

  constructor(private service: MeetingService, private modalCtrl: ModalController,
              private calendar: CalendarService, public modules: ModuleService,
              private toaster: ToastService) {
  }

  ngAfterViewInit() {
    Contacts.requestPermissions();
    if (this.allowCarParkWithInvitation && this.modules.has('booking_invitees_parking')) {
      this.addEmails = [{email: '', parking: 1, invite_as_host: false}];
    }
    this.service.recommendedAttendees().then(attendees => {
      this.recommended = attendees;
      this.getContacts();
    });
  }

  getContacts() {
    Contacts.getContacts({
      projection: {
        name: true,
        emails: true,
      }
    }).then(response => {
      response.contacts.forEach(contact => {
        if (contact.emails) {
          contact.emails.forEach(email => {
            if (!this.recommended.some(c => c.email === email.address)) {
              this.recommended.push({
                id: 0,
                email: email.address,
                name: contact.name.display,
              });
            }
          });
        }
      });
    }).catch(() => {
      this.enabledContacts = false;
    });
  }

  setExistingEmails() {
    this.existingEmails = [];
    this.attendees.forEach(attendee => {
      this.existingEmails.push({
        email: attendee.email,
        parking: attendee.carParkReservation ? 2 : attendee.parkingAccess ? 1 : 0,
        attendee_id: attendee.id,
      });
    });
  }

  addAttendees() {
    const attendees = [];

    this.addEmails.forEach(email => {
      if (email.email !== '') {
        attendees.push({
          email: email.email,
          parking: !this.modules.has('booking_invitees_parking') || !this.allowCarParkWithInvitation ? 0 : email.parking,
          invite_as_host: email.invite_as_host,
        });
      }
    });
    this.handleAttendeeUpdate(attendees);
  }

  updateAttendee() {
    this.setExistingEmails();
    setTimeout(() => {
      this.handleAttendeeUpdate([]);
    }, 50);
  }

  handleAttendeeUpdate(attendees) {
    if (this.isNative) {
      attendees.forEach(attendee => {
        attendee.name = attendee.email.split('@')[0];
        attendee.status = '0';
      });
      this.calendar.addEventAttendeesAlt(this.nativeEventId, attendees).then(() => {
        attendees.forEach(attendee => {
          attendee.status = 0;
        });
        const newAttendees = [...this.attendees, ...attendees];
        this.attendeesChange.emit(newAttendees);
        this.service.attendees.next(newAttendees);
        this.addEmails = [{
          email: '',
          parking: this.allowCarParkWithInvitation && this.modules.has('booking_invitees_parking') ? 1 : 0,
          invite_as_host: false
        }];
      });
    } else {
      this.setExistingEmails();
      this.disableBtn = true;
      const allEmails = [...this.existingEmails, ...attendees];

      if (allEmails.some(item => {
        const matching = item.email === '' || RegExp(
          // eslint-disable-next-line max-len
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ).test(item.email);
        return !matching;
      })) {
        this.toaster.toast('Only emails are allowed for invitations', 'user');
        this.disableBtn = false;
        return;
      }
      this.service.inviteAttendees(this.meetingId, allEmails).then(response => {
        this.handleAttendeeErrors(response);
        if (this.nativeEventId) {
          this.calendar.addEventAttendees(this.nativeEventId, response);
        }
        this.attendees = [...this.attendees, ...response];
        this.attendeesChange.emit(this.attendees);
        this.service.attendees.next(this.attendees);
        this.addEmails = [{
          email: '',
          parking: this.allowCarParkWithInvitation && this.modules.has('booking_invitees_parking') ? 1 : 0,
          invite_as_host: false
        }];
      }).catch(error => {
        Bugsnag.notify(error);
      }).finally(() => {
        this.disableBtn = false;
      });
    }
  }

  handleAttendeeErrors(attendees: any[]) {
    const attendeesWithError = attendees.filter(attendee => attendee.error);
    if (!attendeesWithError.length) {
      return;
    }
    let errorMsg = '';
    attendeesWithError.forEach((attendee, index) => {
      if (index !== 0) {
        errorMsg += '\n';
      }
      errorMsg += attendee.error;
    });
    this.toaster.toast(errorMsg);
  }

  attendeeSearchChanged(search, index) {
    const emptyEmails = this.addEmails.filter(data => data.email === '');
    if (emptyEmails.length === 0) {
      this.addEmails.push({
        email: '',
        parking: this.allowCarParkWithInvitation && this.modules.has('booking_invitees_parking') ? 1 : 0,
        invite_as_host: false
      });
    } else if (emptyEmails.length > 1) {
      this.addEmails.splice(this.addEmails.indexOf(emptyEmails[0]), 1);
    }
    let splitSearch = [];
    if (search.includes('>') && search.includes('<')) {
      splitSearch = search.split(/[;]+/);
      if (splitSearch.length > 1) {
        splitSearch.forEach((item, i) => {
          splitSearch[i] = item.split('<')[1].replace('>', '').replaceAll(' ', '');
        });
      } else {
        this.addEmails[index].email = search.split('<')[1].replace('>', '').replaceAll(' ', '');
      }
    } else {
      splitSearch = search.split(/[ ,;]+/);
    }

    if (splitSearch.length > 1 && splitSearch[1].length > 0) {
      splitSearch.forEach((item, i) => {
        if (i === 0) {
          this.addEmails[index].email = item;
        } else if (i === 1) {
          this.addEmails[index + 1].email = item;
        } else {
          this.addEmails.push({email: item});
        }
      });
      setTimeout(() => {
        const lastEdited = document.querySelector(`.attendee-${this.addEmails.length - 1} input`) as HTMLElement;
        lastEdited.focus();
      });
    }
  }

  async requestContacts(event: any, justDenied = false) {
    event.stopPropagation();
    event.preventDefault();
    const response = await Contacts.requestPermissions();

    if (response.contacts === 'granted') {
      this.getContacts();
    }
    if (response.contacts === 'denied' && !justDenied) {
      await NativeSettings.open({
        optionAndroid: AndroidSettings.ApplicationDetails,
        optionIOS: IOSSettings.App
      });
      await this.requestContacts(event, true);
    }
  }

  showParkingModal(index: number) {
    this.openParkingModal = index;
    this.selectedParkingSetting = this.addEmails[index].parking;
  }

  setParkingSetting(value: number) {
    this.selectedParkingSetting = value;
  }

  confirmParkingSelection() {
    this.addEmails[this.openParkingModal].parking = this.selectedParkingSetting;
    this.openParkingModal = undefined;
  }

  showOrganizerModal(index: number) {
    this.openOrganizerModal = index;
    this.selectedHostSetting = this.addEmails[index].invite_as_host;
  }

  setHostSetting(value: boolean) {
    this.selectedHostSetting = value;
  }

  confirmHostSetting() {
    const currentHost = this.addEmails.find(e => e.host);
    if (currentHost) {
      currentHost.host = false;
    }
    this.addEmails[this.openOrganizerModal].invite_as_host = this.selectedHostSetting;
    this.openOrganizerModal = undefined;
  }

  //Not in use atm contact emails get added to the recommended list (if not already in it)
  async contacts(index) {
    const response = await Contacts.getContacts({
      projection: {
        name: true,
        emails: true,
      }
    });
    const contactsWithEmail = response.contacts.filter(contact => contact.emails).sort((a, b) => {
      if (a.name.display < b.name.display) {
        return -1;
      }
      if (a.name.display > b.name.display) {
        return 1;
      }
      return 0;
    });
    this.modal = await this.modalCtrl.create({
      component: ContactListModalComponent,
      componentProps: {
        contacts: contactsWithEmail,
      },
      initialBreakpoint: 1,
      breakpoints: [0, 1],
    });
    this.modal.present().then(() => {
      const handle = document.querySelector('.show-modal').shadowRoot.querySelector('.modal-handle');
      handle.addEventListener('click', () => {
        this.modal.dismiss();
      });
    });
    const {data} = await this.modal.onWillDismiss();
    if (data.email) {
      this.addEmails[index].email = data.email;
      this.attendeeSearchChanged(data.email, index);
    }
  }
}
