/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {ApiService} from '../../General/ApiService';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {
  constructor(private api: ApiService) {
  }

  public profile() {
    return this.api.get('user/profile');
  }

  public update(firstname: string, lastname: string, phone: string, twoFactorAuth: boolean, propertyId: number) {
    const params = {
      firstname,
      lastname,
      phone,
      real_2fa_enabled: twoFactorAuth,
      defaultProperty_id: propertyId,
    };
    return this.api.post('user/update', params);
  }

  public updateConsent(name: string, allowed: boolean) {
    const params = {
      consent: name,
      set_to: allowed,
    };
    return this.api.post('v1/consents/set', params);
  }

  public updatePreference(name: string, allowed: boolean) {
    const params = {
      preference: name,
      setTo: allowed,
    };
    return this.api.post('user/preferences', params);
  }

  public offices() {
    return this.api.get('user/offices');
  }

  public invitableColleagues(date: string) {
    return this.api.get('invite/colleague', {date});
  }

  public inviteColleague(date: string, userId: number, propertyId: number) {
    const params = {
      date,
      user_id: userId,
      property: propertyId,
    };
    return this.api.post('invite/colleague', params);
  }

  public marketPlaces() {
    return this.api.get('user/marketplaces');
  }

  public updateDefaultCustomer(defaultCustomerId: number) {
    const params = {
      defaultCustomerId,
    };
    return this.api.post('user/update', params);
  }

  public verifyEmail(email: string, token: string) {
    const params = {
      email,
      token,
    };
    return this.api.post('user/verifyEmail', params);
  }

  public updateServicePassword() {
    return this.api.post('user/printAccount/renew');
  }

  public prefillUserInformation(userQr) {
    const params = {
      userQr
    };
    return this.api.get('user/prefillInformation', params);
  }

  public deleteDelegate(delegateUserId: number) {
    return this.api.post(`booking/delegate/${delegateUserId}/revoke`);
  }
}
