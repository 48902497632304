import {MeetingPage} from './meeting.page';
import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {MeetingPageRoutingModule} from './meeting-routing.module';
import {AppComponentsModule} from '../../app_components.module';
import {MeetingInformationComponent} from './components/meeting-information/meeting-information.component';
import {MeetingDirectionsComponent} from './components/meeting-directions/meeting-directions.component';
import {MeetingAttendeesComponent} from './components/meeting-attendees/meeting-attendees.component';
import {TabsPageModule} from '../../components/page-tabs/tabs/tabs.module';
import {MeetingActionsComponent} from './components/meeting-actions/meeting-actions.component';
import {MeetingAssetsComponent} from './components/meeting-assets/meeting-assets.component';
import {MeetingMessageModalComponent} from './components/meeting-message-modal/meeting-message-modal.component';
import {MeetingBannerComponent} from './components/meeting-banner/meeting-banner.component';
import {MeetingRsvpComponent} from './components/meeting-rsvp/meeting-rsvp.component';
import {MeetingActiveActionsComponent} from './components/meeting-active-actions/meeting-active-actions.component';
import {AlternativeRoomsModalComponent} from './modals/alternative-rooms/alternative-rooms-modal.component';
import {MeetContainerComponentModule} from '../meet/components/container/meet-container.module';
import {MeetingOrdersComponent} from './components/meeting-orders/meeting-orders.component';
import {MeetingHeaderComponent} from './components/meeting-header/meeting-header.component';
import {MeetingNavigationComponent} from './components/meeting-navigation/meeting-navigation.component';
import {MeetingRoomControlsComponent} from './components/meeting-room-controls/meeting-room-controls.component';
import {RoomContainerComponentModule} from '../room/components/container/room-container.module';
import {MeetingTeamsComponent} from './components/meeting-teams/meeting-teams.component';
import {EditMeetingModalComponent} from './modals/edit-meeting-modal/edit-meeting-modal.component';
import {RoomDoorComponent} from '../room/components/tv/tv.component';
import {
  MeetingShareVisitorPassComponent
} from './components/meeting-share-visitor-pass/meeting-share-visitor-pass.component';
import {ShareVisitorPassModalComponent} from './modals/share-visitor-pass/share-visitor-pass-modal.component';
import {MeetingInviteOptionsComponent} from './components/meeting-invite-options/meeting-invite-options.component';
import {
  MeetingPendingOrganizerComponent
} from './components/meeting-pending-organizer/meeting-pending-organizer.component';

@NgModule({
    imports: [
        IonicModule,
        FormsModule,
        CommonModule,
        MeetingPageRoutingModule,
        AppComponentsModule,
        TabsPageModule,
        MeetContainerComponentModule,
        RoomContainerComponentModule,
    ],
    declarations: [
        MeetingPage,
        MeetingActiveActionsComponent,
        MeetingInformationComponent,
        MeetingDirectionsComponent,
        MeetingAttendeesComponent,
        MeetingActionsComponent,
        MeetingAssetsComponent,
        MeetingMessageModalComponent,
        MeetingBannerComponent,
        MeetingRsvpComponent,
        AlternativeRoomsModalComponent,
        MeetingOrdersComponent,
        MeetingHeaderComponent,
        MeetingNavigationComponent,
        MeetingRoomControlsComponent,
        MeetingTeamsComponent,
        EditMeetingModalComponent,
        RoomDoorComponent,
        MeetingShareVisitorPassComponent,
        ShareVisitorPassModalComponent,
        MeetingInviteOptionsComponent,
        MeetingPendingOrganizerComponent,
    ],
    exports: [
        MeetingPendingOrganizerComponent
    ]
})
export class MeetingPageModule {
}
