/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {ApiService} from '../../General/ApiService';
import {TenantService} from '../../Tenant/TenantService';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {
  private client = {
    client_id: 3,
  };

  private dhqClient = {
    client_id: 6,
  };

  constructor(public api: ApiService, private tenantService: TenantService) {
    this.tenantService.tenantConfig.subscribe((data) => {
      if (data) {
        this.client.client_id = this.tenantService.getLogin().client_id;
      }
    });
  }

  public login(username: string, password: string, step: string) {
    const params = {
      email: username,
      password,
      grant_type: 'password',
      client_id: this.client.client_id,
      step,
    };
    return this.api.post('login_v2', params).then(data => {
      this.api.setToken(data.access_token, data.refresh_token);
      return data;
    });
  }

  public smsLogin(phone: string, step: string, code?: string) {
    const params = {
      phone,
      ...(code && {sms_code: code}),
      otp: true,
      grant_type: 'password',
      client_id: this.client.client_id,
      step,
    };
    return this.api.post('login_v2', params).then(data => {
      if (data.message) {
        return data;
      } else {
        this.api.setToken(data.access_token, data.refresh_token);
        return data;
      }
    });
  }

  public checkAuthMethod(username: string) {
    return this.api.get(`user/2fa-enabled`, {email: username});
  }

  public tokenLogin(token: string, pendingRegistrationToken: string = null) {
    const params = {
      token,
      ...(pendingRegistrationToken && {pendingRegistrationToken}),
    };
    return this.api.post('auth/loginWithTempAccessToken', params).then(data => {
      this.api.setToken(data.token);
      return data;
    });
  }

  public qrLoginStepOne(token) {
    return this.api.post('v1/qr_login', {token});
  }

  public qrLoginStepTwo(token, uuid) {
    return this.api.post(`v1/qr_login/${uuid}`, {token});
  }

  public authTransferTokenUrl(redirectUrl: string) {
    const params = {
      redirect_url: redirectUrl,
    };
    return this.api.get('v1/sso/authentication/transfer/token-url', params);
  }

  public checkedIn() {
    return this.api.get('v1/kiosks/member/checkedIn');
  }

  public refreshLoginToken(refreshToken: string) {
    const params = {
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
      ...this.client
    };
    return this.api.fullPathPost(this.api.url + 'oauth/token', params).then(data => {
      this.api.setToken(data.access_token, data.refresh_token);
      return data;
    });
  }

  public setApiAuthService(service) {
    this.api.setAuthService(service);
  }

  public reverseOtpCode(step: string) {
    return this.api.get('getSmsLoginToken', {step});
  }

  public messagingReport(phone: string) {
    const params = {
      type: 'sms-no-response',
      phone,
    };
    return this.api.post('v1/messaging/report', params);
  }

  public externalQrAuthentication(token: string) {
    const params = {
      token,
    };
    return this.api.post('app-authentication', params);
  }

}
