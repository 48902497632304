import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Attendee} from '../../../../services/Meetings/attendee.interface';
import {MeetingService} from '../../../../services/Meetings/MeetingService';
import {ToastService} from '../../../../services/General/ToastService';
import Bugsnag from '@bugsnag/js';

@Component({
  selector: 'app-meeting-pending-organizer',
  templateUrl: './meeting-pending-organizer.component.html',
  styleUrls: ['./meeting-pending-organizer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MeetingPendingOrganizerComponent {
  @Input() user: any;
  @Input() host: any;
  @Input() moderator = false;
  @Input() pendingOrganizer: Attendee;
  @Input() meetingId: any;
  @Output() reloadRequired = new EventEmitter<void>();
  allowWithoutApproval = false;

  constructor(private meetingService: MeetingService, private toaster: ToastService) {
  }

  acceptOrganizerInvite() {
    this.meetingService.acceptOrganizerInvite(this.meetingId, this.allowWithoutApproval).then(() => {
      this.reloadRequired.emit();
    }).catch(err => {
      this.toaster.toast(err.error?.meessge ?? 'Failed to update organizer status');
      Bugsnag.notify(err);
    });
  }

  declineOrganizerInvite() {
    this.meetingService.declineOrganizerInvite(this.meetingId).then(() => {
      this.reloadRequired.emit();
    }).catch(err => {
      this.toaster.toast(err.error?.meessge ?? 'Failed to update organizer status');
      Bugsnag.notify(err);
    });
  }

  revokeOrganizerInvite() {
    this.meetingService.revokeOrganizerInvite(this.meetingId).then(() => {
      this.reloadRequired.emit();
    }).catch(err => {
      this.toaster.toast(err.error?.meessge ?? 'Failed to update organizer status');
      Bugsnag.notify(err);
    });
  }
}
