/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {TagsService} from '../Tags/TagsService';
import {AssetsService} from '../Assets/AssetsService';
import {NavController} from '@ionic/angular';
import {AuthService} from '../Auth/AuthService';
import {ToastService} from '../General/ToastService';
import {SeatService} from '../Seats/SeatService';
import {UserService} from '../User/UserService';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
import Bugsnag from '@bugsnag/js';
import {ModuleService} from '../Modules/ModuleService';
import {TenantService} from '../Tenant/TenantService';
import {ApiService} from '../General/ApiService';
import {RoomService} from '../Rooms/RoomService';

@Injectable({
  providedIn: 'root'
})
export class ScanDataService {
  modal: any;
  redirectUrl: string = null;

  constructor(private nav: NavController, private tags: TagsService,
              private assets: AssetsService, private auth: AuthService,
              private toaster: ToastService, private seat: SeatService,
              private user: UserService, private browser: InAppBrowser,
              private modules: ModuleService, private api: ApiService,
              private tenantService: TenantService, private roomService: RoomService) {
  }

  public process(data: string) {
    let tenant = null;
    let extra = null;
    if (data.includes('?')) {
      const dataArr = data.split('?');
      data = dataArr[0];
      extra = dataArr[1];
      if (dataArr[1].includes('redirect_url')) {
        this.redirectUrl = dataArr[1].replace('redirect_url=', '');
      } else {
        tenant = dataArr[1].replace('t=', '');
      }
    }
    if (!data.includes('/')) {
      this.handleParkingTicket(data, null);
    } else {
      const result = data.replace('https://', '')
        .replace('http://', '')
        .replace('meetdistrict://', '')
        .replace('digitalhq://', '')
        .split('/');
      if (result.length > 1) {
        if (result[1].substring(0, 2) === 'kp') {
          this.handleParkingTicket(data, result[1]);
        } else {
          switch (result[1]) {
            case 'tag':
              this.handleTag(result[2], tenant);
              break;
            case 'asset':
              this.handleAsset(result[2], null, tenant);
              break;
            case 'seat':
              this.handleSeatFromKiosk(result[2]);
              break;
            case 'service':
              this.handleSupport();
              break;
            case 'event':
              this.handleBooking(result[2]);
              break;
            case 'bookings':
              this.handleBooking(result[2]);
              break;
            case 'book':
              this.handleBook(result[2]);
              break;
            case 'academy':
              this.handleAcademy(result[2]);
              break;
            case 'community':
              this.handleCommunity(result[2]);
              break;
            case 'login':
              if (!result[0] || this.api.url.includes(result[0])) {
                this.handleLogin(result[2]);
              } else {
                this.handleRogueUrl(data, extra);
                this.home();
              }
              break;
            default:
              this.handleRogueUrl(data, extra);
              this.home();
              break;
          }
        }
      } else {
        this.handleRogueUrl(data, extra);
        this.home();
      }
    }
  }

  handleRogueUrl(url, extra) {
    if (extra) {
      url = url + '?'+ extra;
    }
    if ((url.includes('https://') || url.includes('http://')) && !url.includes('app.digitalhq.com') && !url.includes('meetdistrict.com')) {
      this.browser.create(url, '_system');
    }
  }

  handleTag(tagId, tenant = null) {
    this.tags.tag(tagId, tenant).then(tag => {
      if (tagId.includes('sign_')) {
        this.toaster.toast('Validated');
        this.handleRedirect();
        this.home();
        return;
      }
      switch (tag.type) {
        case 'asset':
          this.handleAsset(tag.id, tagId);
          break;
        case 'seat':
          this.handleSeatFromTag(tag.id);
          break;
        default:
          this.user.profile().then(profile => {
            if (profile.roles.some(role => role.name === 'admin')) {
              this.toaster.toast('Incorrect tag type');
              this.nav.navigateBack(['/edit-tag'], {state: {tagId}});
            } else {
              this.toaster.toast('Tag not found');
              this.home();
            }
          });
          break;
      }
    }).catch(error => {
      Bugsnag.notify(error);
      if (error.error.message) {
        this.toaster.toast(error.error.message);
        this.home();
      } else {
        if (tagId.includes('sign_')) {
          this.toaster.toast('Validation Failed');
          this.home();
          return;
        }
        this.toaster.toast('Tag not found');
        this.user.profile().then(profile => {
          if (profile.roles.some(role => role.name === 'admin') && error.status === 422) {
            this.nav.navigateBack(['/edit-tag'], {state: {tagId}});
          } else {
            this.home();
          }
        });
      }
    });
  }

  handleRedirect() {
    if (this.redirectUrl) {
      this.browser.create(this.redirectUrl, '_system');
      this.redirectUrl = null;
    }
  }

  handleLogin(token) {
    try {
      this.auth.qrLogin(token).then(response => {
        this.home();
        if (typeof response.message != 'undefined') {
          this.toaster.toast(response.message);
        }
      });
    } catch (err) {
      Bugsnag.notify(err);
      this.toaster.toast('Invalid code');
      this.home();
    }
  }

  handleBooking(id) {
    this.modules.hasAsync('booking').then(hasModule => {
      if (hasModule) {
        this.nav.navigateBack(['/meeting', {id}], {state: {topic: 'app'}});
      } else {
        this.noData();
      }
    });
  }

  handleBook(id) {
    this.modules.hasAsync('booking').then(hasModule => {
      if (hasModule) {
        this.roomService.room(id).then(room => {
          this.nav.navigateBack(['/meet'], {state: {action: 'book-now', roomName: room.name}});
        });
      } else {
        this.noData();
      }
    });
  }

  handleSupport() {
    this.modules.hasAsync('help_center').then(hasModule => {
      if (hasModule) {
        this.nav.navigateBack(['/service'], {state: {topic: 'app'}});
      } else {
        this.noData();
      }
    });
  }

  handleAcademy(id) {
    this.modules.hasAsync('academy').then(hasModule => {
      if (hasModule) {
        this.nav.navigateBack(['/academy', {id}]);
      } else {
        this.noData();
      }
    });
  }

  handleCommunity(id) {
    this.modules.hasAsync('community').then(hasModule => {
      if (hasModule) {
        this.nav.navigateBack(['/community', {id}]);
      } else {
        this.noData();
      }
    });
  }

  handleSeatFromKiosk(combinedToken) {
    this.modules.hasAsync('flex_seat_reservation').then(hasModule => {
      if (hasModule) {
        const splitToken = combinedToken.split('-');
        const kioskId = splitToken[0];
        const hash = splitToken[1];
        this.seat.seatFromKiosk(kioskId, hash);
      } else {
        this.noData();
      }
    });
  }

  handleSeatFromTag(seatId) {
    this.modules.hasAsync('flex_seat_reservation').then(hasModule => {
      if (hasModule) {
        this.seat.seat(seatId);
      } else {
        this.noData();
      }
    });
  }

  handleAsset(assetId, tagId = null, tenant = null) {
    this.assets.scanDataService = this;
    this.assets.processAsset(assetId, tagId, tenant);
  }

  handleParkingTicket(data, type) {
    this.modules.hasAsync('parking_validation').then(hasModule => {
      if (hasModule) {
        if (type && type.substring(0, 2) === 'kp') {
          const url = new URL(data);
          const urlParams = new URLSearchParams(url.search);
          data = urlParams.get('i');
        }
        this.nav.navigateBack(['/parking-validator'], {state: {barcode: data}});
      } else {
        this.noData();
      }
    });
  }

  handleExternalAuthentication(data: string) {
    try {
      const token = data.split('mQ-')[1].split(' ')[0].replace(' ', '');
      this.auth.externalQrAuthentication(token).then(response => {
        this.home();
        this.toaster.toast('Successfully logged in');
      });
    } catch(err) {
      this.noData();
    }
  }

  noData() {
    this.toaster.toast('No data found for scanned QR/link');
    this.home();
  }

  home() {
    this.nav.navigateRoot('/home');
  }
}
