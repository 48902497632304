/* eslint-disable @typescript-eslint/naming-convention */
export const options = [
  'tv',
  'fan',
  'sun',
  'bell',
  'home',
  'home2',
  'shop',
  'meet',
  'door',
  'more',
  'euro',
  'scan',
  'token',
  'pause',
  'power',
  'clock',
  'frost',
  'x_mark',
  'basket',
  'blinds',
  'person',
  'wallet',
  'service',
  'service-solid',
  'log_out',
  'printer',
  'question',
  'calendar',
  'teams_app',
  'lock_open',
  'lock_close',
  'community',
  'user_group',
  'light_bulb',
  'arrow_up_2',
  'desk_light',
  'calendar_2',
  'thermometer',
  'arrow_right',
  'arrow_left',
  'arrow_down_2',
  'light_bulb_2',
  'numbered_list',
  'arrow_right_2',
  'arrow_small_up',
  'person_in_chair',
  'parking',
  'parking-simple',
  'wifi',
  'wifi-disconnect',
  'euro-light',
  'arrow_small_down',
  'meetdistrict_logo_square',
  'edit',
  'clock-return',
  'search',
  'trash',
  'circle-plus',
  'circle-plus-outline',
  'face-covered',
  'chevron-right',
  'chevron-left',
  'chevrons',
  'chevron',
  'arrow-right-out-box-light',
  'arrow-right-out-box-dark',
  'calendar-muted',
  'seat',
  'trash-blue',
  'wallet-blue',
  'silent-speaker',
  'user',
  'attending',
  'not-responded',
  'attending-virtual',
  'not-attending',
  'door-open',
  'arrows-return',
  'edit-square',
  'user-add',
  'x_mark-red',
  'food',
  'arrows_reload',
  'check',
  'camera',
  'folder',
  'coffee',
  'barcode',
  'star-check',
  'qr',
  'info',
  'building',
  'ticket',
  'plus',
  'mail',
  'sms',
  'whatsapp',
  'person-badge',
  'settings',
  'user-gray2',
  'eye',
  'eye-remove',
  'arrow-right-blue',
  'arrow_small_down_white',
  'check-rounded',
  'chip',
  'coffee-outline',
  'corporate_expired',
  'corporate_will_expire',
  'door_closed',
  'download',
  'loader',
  'nespresso',
  'no-notification',
  'refresh',
  'trees',
  'user-gray',
  'location',
  'webcam',
  'info-outline',
  'question-round',
  'question-circle-clear',
  'upload-outline',
  'pdf',
  'pencil',
  'ok',
  'attending-virtual-outline',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'doc',
  'docx',
  'jpg',
  'jpeg',
  'png',
  'file',
  'star',
  'copy',
  'map',
  'stop',
  'envelop',
  'contacts',
  'bike',
  'settings-outline',
  'min',
  'food-2',
  'bancontact',
  'visa',
  'maestro',
  'apple-pay',
  'academy',
  'community-outline',
  'car-charging',
  'lightning',
  'star-checked',
  'filter',
  'car',
  'plus-cube',
  'teams-no-color',
  'hdmi',
  'usbc',
  'tenant-login',
  'location2',
  'coin',
  'chevron-down',
  'default-app-icon',
  'Sodexo-app-icon',
  'long-arrow-right',
  'dhq-app-icon-small',
  'whiteboard',
  'share',
  'user-host',
  'checked-in',
  'no-parking',
  'parking-reserved',
  'calendar-user',
  'tv-bubbles',
  'door-bubbles',
  'hour-glass-circle',
  'vertical-dots'
] as const;

export type SvgIcon = typeof options[number];

