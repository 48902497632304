/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {ApiService} from '../../General/ApiService';
import {AvailableRoomApiParameters} from './available-room-api-parameters.interface';
import {DateTime} from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class MeetingApiService {
  constructor(private api: ApiService) {
  }

  public myMeetings(startDateFrom: string, startDateTill: string) {
    const params = {
      startDateFrom,
      startDateTill
    };
    return this.api.get('booking/attending-meetings', params);
  }

  public update(meetingId: number, subject: string, description: string) {
    const params = {
      subject,
      description,
    };
    return this.api.post(`booking/${meetingId}`, params);
  }

  public updateNote(attendeeId: number, note: string) {
    const params = {
      attendeeId,
      note,
    };
    return this.api.post('v3/bookings/attendees/note', params);
  }

  public onlineMeeting(bookingId: number, action: string) {
    return this.api.post(`v1/roombookings/${bookingId}/online-meeting/${action}`);
  }

  public availableMeetingRooms(availableRoomApiParamenters: AvailableRoomApiParameters) {
    if (availableRoomApiParamenters.alternative) {
      return this.api.get('meetingroom/alternative-rooms', availableRoomApiParamenters);
    }
    return this.api.get('meetingroom/available-rooms', availableRoomApiParamenters);
  }

  public availableCapacityMeetingRooms(availableRoomApiParamenters: AvailableRoomApiParameters) {
    return this.api.get('meetingroom/available-unspecified-rooms', availableRoomApiParamenters);
  }

  public suggestMeetingRoom(from: string, till: string, attendeeCount: number) {
    const params = {
      from,
      till,
      attendeeCount
    };
    // eslint-disable-next-line @typescript-eslint/no-shadow
    return this.api.get('v2/meetingroom/available-rooms/suggestion', params);

  }

  public meeting(meetingId: number) {
    return this.api.get(`booking/${meetingId}`);
  }

  public cancelMeeting(meetingId: number) {
    return this.api.post(`booking/${meetingId}/cancel`);
  }

  public endMeeting(meetingId: number) {
    return this.api.post(`booking/setEndTime/${meetingId}`);
  }


  public calendarInformation(meetingId: number) {
    return this.api.get(`booking/${meetingId}/calendarInformation`);
  }

  public setNativeEventId(meetingId: number, eventId: string) {
    const params = {
      eventId,
    };
    return this.api.post(`v3/bookings/${meetingId}/set-native-event`, params);
  }

  public replyToInvite(attendeeId: number, status: number, addedToNativeCalendar: boolean = null) {
    const params = {
      status,
      ...(addedToNativeCalendar && {addedToNativeCalendar}),
    };
    return this.api.post(`booking/replyInvitation/${attendeeId}`, params);
  }

  public recommendedAttendees() {
    return this.api.get('recommended-attendees');
  }

  public inviteAttendees(meetingId: number, emails: Array<object>) {
    const params = {
      roomBooking_id: meetingId,
      attendees: emails,
    };
    return this.api.post('booking/addAttendees', params);
  }

  public removeAttendee(attendeeId: number) {
    const params = {
      attendee_id: attendeeId,
    };
    return this.api.post('booking/removeAttendee', params);
  }

  public notifyHost(meetingId: number, message: string) {
    const params = {
      roomBooking_id: meetingId,
      message,
    };
    return this.api.post('booking/sendMessageToOrganizer', params);
  }

  public setHideSelf(meetingId: number, hideSelf: boolean) {
    const params = {
      hide_for_participants: hideSelf,
    };

    return this.api.post(`booking/${meetingId}/hide-yourself-for-participants`, params);
  }

  public setHideParticipants(meetingId: number, hideParticipants: boolean) {
    const params = {
      restrict_participants_visibility: hideParticipants,
    };

    return this.api.post(`booking/${meetingId}/hide-participants`, params);
  }

  public locationInvite(
    emails: Array<string>,
    parking: Array<number>,
    date: DateTime,
    propertyId: number,
    officeAccess: boolean,
    corporateWalletAccess: boolean
  ) {
    const params = {
      emails,
      parking,
      date: date.toFormat('yyyy-LL-dd HH:mm:ss'),
      propertyId,
      officeAccess,
      invite_as_colleagues: corporateWalletAccess,
    };

    return this.api.post('user/invite', params);
  }

  public locationInvitees() {
    return this.api.get('user/invitees');
  }

  public getScheduledMeetingsForIds(from: string, till: string, meetingIds: Array<number>) {
    const params = {
      dateFrom: from,
      dateTill: till,
      'room_ids[]': meetingIds,
    };
    return this.api.get('meetingroom/scheduled-bookings', params);
  }

  public replyToVisitorRegistration(notificationId, action) {
    const params = {
      notification_id: notificationId,
      action,
    };
    return this.api.post('visitors/registration/reply', params);
  }

  public defaultVisitorRegistration(notificationId) {
    const params = {
      notificationId,
    };
    return this.api.post('visitors/registration/default', params);
  }

  public replaceMeetingRoom(meetingId: number, roomId: number) {
    const params = {
      roomId,
    };
    return this.api.post(`v1/roombookings/${meetingId}/room/replace`, params);
  }

  public assignOrganizer(meetingId: number, attendeeId: number) {
    const params = {
      attendee_id: attendeeId,
    };
    return this.api.post(`booking/${meetingId}/assign-organizer`, params);
  }

  public acceptOrganizerInvite(meetingId: number, allowWithoutApproval: boolean) {
    const params = {
      delegate: allowWithoutApproval,
    };
    return this.api.post(`booking/${meetingId}/organizer/accept`, params);
  }

  public declineOrganizerInvite(meetingId: number) {
    return this.api.post(`booking/${meetingId}/organizer/decline`);
  }

  public revokeOrganizerInvite(meetingId: number) {
    return this.api.post(`booking/${meetingId}/organizer/revoke`);
  }

  public setOutlookEventId(meetingId: number, outlookEventId: string) {
    const params = {
      outlookEventId
    };
    return this.api.post(`v1/roombookings/${meetingId}/outlook-event`, params);
  }
}
