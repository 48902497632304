import {Injectable} from '@angular/core';
import {CoffeeApiService} from './API/CoffeeApiService';
import {BehaviorSubject} from 'rxjs';
import {Wallet} from '../../../pages/wallets/interfaces/wallet.interface';
import {StorageService} from '../../Storage/StorageService';

@Injectable({
  providedIn: 'root'
})
export class CoffeeService {
  coffeePadSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private api: CoffeeApiService, private storage: StorageService) {
  }

  public mainMachine() {
    return this.api.mainMachine();
  }

  public quickBuy(assetId: number, mac: string, showQuantity: boolean, wallet: Wallet, bssid: string = null) {
    return this.api.quickBuy(assetId, mac, showQuantity, wallet, bssid);
  }

  public setCoffeePadSelected(selected: boolean) {
    this.coffeePadSelected.next(selected);
  }

  public addLastUsedMachine(machine: {location: string; assetId: number; mac: string; showQuantity: boolean|number}) {
      this.storage.set('main-coffee-machine', machine);

    // Promise.all([
    //   this.storage.get('main-coffee-machine'),
    //   this.storage.get('last-used-coffee-machines')
    // ]).then(values => {
    //   let mainMachine = values[0];
    //   let lastUsedMachines = values[1];
    //   if (!lastUsedMachines) {
    //     lastUsedMachines = [];
    //   }
    //   lastUsedMachines.push(machine);
    //   while (lastUsedMachines.length > 3) {
    //     lastUsedMachines.shift();
    //   }
    //   this.storage.set('last-used-coffee-machines', lastUsedMachines);
    //   if (!mainMachine) {
    //     mainMachine = machine;
    //     this.storage.set('main-coffee-machine', mainMachine);
    //   } else {
    //     if (lastUsedMachines.every(m => JSON.stringify(m) === JSON.stringify(machine)) &&
    //       JSON.stringify(mainMachine) !== JSON.stringify(machine)) {
    //       mainMachine = machine;
    //       this.storage.set('main-coffee-machine', mainMachine);
    //     }
    //   }
    // });
  }
}
