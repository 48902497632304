/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {StorageService} from '../Storage/StorageService';
import {Calendar} from '@awesome-cordova-plugins/calendar/ngx';
import {CalendarInterface} from './calendar.interface';
import {DateTime} from 'luxon';
import {MainAgendaItem} from '../../pages/home/components/agenda/agenda-items.interface';
import {PropertyService} from '../Properties/PropertyService';
import {CacheService} from '../Cache/CacheService';
import {Platform} from '@ionic/angular';
import {TenantService} from '../Tenant/TenantService';
import {ModuleService} from '../Modules/ModuleService';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  storageCalendars: Array<CalendarInterface> = [];
  newCalendars: Array<CalendarInterface> = [];
  events: Array<MainAgendaItem> = [];
  hasPermission = false;

  constructor(private calendar: Calendar, private storage: StorageService,
              private propertyService: PropertyService, private cache: CacheService,
              private platform: Platform, private modules: ModuleService,
              private tenantService: TenantService) {
  }

  checkPermission() {
    return this.calendar.hasReadWritePermission().then(hasPermission => {
      this.hasPermission = hasPermission;
      return hasPermission;
    });
  }

  async requestPermission() {
    try {
      if (this.modules.has('native_calendar')) {
        await this.calendar.requestReadWritePermission();
        this.hasPermission = true;
      }
      return true;
    } catch (e) {
      return false;
    }
  }

  public calendars(onlyEnabled = false) {
    if (!this.modules.has('native_calendar') || !this.hasPermission) {
      return Promise.resolve([]);
    }
    return this.storage.get('calendars').then(calendars => {
      this.storageCalendars = calendars ?? [];
      if (onlyEnabled) {
        this.storageCalendars = this.storageCalendars.filter(calendar => calendar.enabled);
      }
      return this.storageCalendars;
    });
  }

  public setCalendars(calendars: Array<CalendarInterface>) {
    this.storageCalendars = calendars;
    return this.storage.set('calendars', calendars);
  }

  public getEvents(startDate: Date = DateTime.now().startOf('day').toJSDate(),
                   endDate: Date = DateTime.now().endOf('day').toJSDate(),
                   includeMeetDistrictEvents: boolean = false,
                   includeDisallowedCalendars: boolean = false,
                   includeEndedEvents: boolean = false) {
    this.events = [];
    if (this.hasPermission && this.modules.has('native_calendar')) {
      return this.calendar.listEventsInRange(startDate, endDate).then(eventsInRange =>
        this.reformatIosEvents(eventsInRange).then(events => {
          events = this.filterAllowedEvents(events, includeMeetDistrictEvents, includeDisallowedCalendars, includeEndedEvents);
          return this.formatEvents(events).then(formattedEvents => {
            this.events = formattedEvents;
            return this.events;
          });
        }));
    } else {
      return Promise.resolve(this.events);
    }
  }

  public getEventById(eventId: string) {
    if (this.hasPermission && this.modules.has('native_calendar')) {
      return this.calendar.getEventById(eventId).then(event => {
        if (this.platform.is('android')) {
          event.from = DateTime.fromMillis(event.dtstart);
          event.till = DateTime.fromMillis(event.dtend);
        } else {
          if (event.startDate.includes('AM') || event.startDate.includes('PM')) {
            event.from  = DateTime.fromFormat(event.startDate.replace(/\u202F/g, ' '), 'yyyy-LL-dd h:mm:ss a');
            event.till = DateTime.fromFormat(event.endDate.replace(/\u202F/g, ' '), 'yyyy-LL-dd h:mm:ss a');
          } else {
            event.from = DateTime.fromFormat(event.startDate, 'yyyy-LL-dd HH:mm:ss');
            event.till = DateTime.fromFormat(event.endDate, 'yyyy-LL-dd HH:mm:ss');
          }
        }
        return event;
      });
    } else {
      return Promise.resolve(null);
    }
  }

  async reformatIosEvents(events) {
    if (!this.platform.is('ios')) {
      return events;
    }
    return events.map(event => {
      let start;
      let end;
      if (event.startDate.includes('AM') || event.startDate.includes('PM')) {
        start = DateTime.fromFormat(event.startDate.replace(/\u202F/g, ' '), 'yyyy-LL-dd h:mm:ss a');
        end = DateTime.fromFormat(event.endDate.replace(/\u202F/g, ' '), 'yyyy-LL-dd h:mm:ss a');
      } else {
        start = DateTime.fromFormat(event.startDate, 'yyyy-LL-dd HH:mm:ss');
        end = DateTime.fromFormat(event.endDate, 'yyyy-LL-dd HH:mm:ss');
      }
      return {
        ...event,
        eventLocation: event.location ?? '',
        event_id: event.id,
        allDay: start.toMillis() === start.startOf('day').toMillis() &&
          end.toMillis() === end.endOf('day').set({millisecond: 0}).toMillis(),
        dtstart: start.toMillis(),
        dtend: end.toMillis(),
      };
    });
  }

  filterAllowedEvents(events, includeMeetDistrictEvents, includeDisallowedCalendars, includeEndedEvents) {
    const allowedCalendarIds = this.storageCalendars.map(calendar => calendar.enabled && calendar.id);
    return events.filter(event =>
      (includeEndedEvents || DateTime.fromMillis(event.dtend) > DateTime.now()) &&
      (includeDisallowedCalendars || allowedCalendarIds.includes(event.calendar_id)) &&
      //apparently on android the note is added to an event like 20 seconds after the event is created so need to check if it exists
      (includeMeetDistrictEvents || (event.note === undefined || !event.note.split('MD-')[1]))
    );
  }

  formatEvents(events) {
    return this.storage.get('calendar-colors').then(colors => {
      if (!colors) {
        colors = [];
      }
      return events.map(event => {
        let setColor = null;
        const colorFilter = colors.filter(color => color.calendarId === event.calendar_id);
        if (colorFilter.length) {
          setColor = colorFilter[0].color;
        }
        return {
          id: event.event_id,
          subject: event.title,
          allDay: event.allDay,
          from: DateTime.fromMillis(event.dtstart).toFormat('HH:mm'),
          till: DateTime.fromMillis(event.dtend).toFormat('HH:mm'),
          fromDate: DateTime.fromMillis(event.dtstart),
          tillDate: DateTime.fromMillis(event.dtend),
          location: event.eventLocation,
          calendarId: event.calendar_id,
          note: event.note,
          color: setColor,
          type: 'native',
          ...(event.attendees && {attendees: event.attendees}),
        };
      });
    });
  }

  public freshCalendars() {
    if (this.hasPermission && this.modules.has('native_calendar')) {
      return this.calendar.listCalendars().then(newCalendars => {
        this.newCalendars = newCalendars
          .map((c) => ({id: `${c.id}`, name: c.name, enabled: false}));
        return this.newCalendars;
      });
    } else {
      return Promise.resolve([]);
    }
  }

  public refreshCalendars() {
    if (this.hasPermission && this.modules.has('native_calendar')) {
      return this.calendars().then(calendars =>
        this.combineCalendars(calendars).then(
          combined => combined
        ));
    } else {
      return Promise.resolve([]);
    }
  }

  public createEvent(title: string, location: string, description: string, from: DateTime, till: DateTime, calendarId: string = null) {
    if (this.hasPermission && this.modules.has('native_calendar')) {
      if (calendarId) {
        return this.calendar.createEventWithOptions(
          title,
          location,
          description,
          from.toJSDate(),
          till.toJSDate(),
          {calendarId});
      } else {
        return this.calendar.createEvent(title, location, description, from.toJSDate(), till.toJSDate());
      }
    } else {
      return null;
    }
  }

  public createEventInteractively(from: DateTime, till: DateTime, calendarId: string = null) {
    if (calendarId) {
      return this.calendar.createEventInteractivelyWithOptions(
        null,
        null,
        null,
        from.toJSDate(),
        till.toJSDate(),
        {calendarId});
    }
    return this.calendar.createEventInteractively(null, null, null, from.toJSDate(), till.toJSDate());
  }

  public updateEventInteractively(eventId: string) {
    return this.calendar.updateEventInteractively(eventId);
  }

  public getAttendees(eventId: string) {
    if (this.hasPermission && this.modules.has('native_calendar')) {
      //As iOS includes attendees in the event we can return an empty array when this is checked
      if (this.platform.is('ios')) {
        return new Promise<Array<any>>((resolve) => resolve([]));
      }
      return this.calendar.listEventAttendees(eventId)
        .then(data => data)
        .catch(err => {
          throw err;
        });
    } else {
      return Promise.resolve([]);
    }
  }

  public addEventAttendees(eventId: string, attendees: Array<any>) {
    // if (this.hasPermission && this.modules.has('native_calendar')) {
    //   return this.calendar.addEventAttendees(eventId, attendees)
    //     .then(() => {
    //     })
    //     .catch(err => {
    //       throw err;
    //     });
    // } else {
      return Promise.resolve(null);
    // }
  }

  public addEventAttendeesAlt(eventId: string, attendees: Array<any>) {
    if (this.hasPermission && this.modules.has('native_calendar')) {
      return this.calendar.addEventAttendees(eventId, attendees)
        .then(() => {
        })
        .catch(err => {
          throw err;
        });
    } else {
      return Promise.resolve(null);
    }
  }

  public updateEventAttendees(eventId: string, attendees: Array<any>) {
    // if (this.hasPermission && this.modules.has('native_calendar')) {
    //   return this.calendar.updateEventAttendees(eventId, attendees)
    //     .then(() => {
    //     })
    //     .catch(err => {
    //       throw err;
    //     });
    // } else {
      return Promise.resolve(null);
    // }
  }

  public removeEventAttendees(eventId: string, attendees: Array<any>) {
    if (this.hasPermission && this.modules.has('native_calendar')) {
      return this.calendar.removeEventAttendees(eventId, attendees);
    } else {
      return Promise.resolve(null);
    }
  }

  public updateExistingEventNote(existingEventId: string, description: string, location: string) {
    if (this.hasPermission && this.modules.has('native_calendar')) {
      return this.calendar.updateEventNote(
        existingEventId,
        description,
        location
      );
    } else {
      return Promise.resolve(null);
    }
  }

  public updateExistingEventInformation(existingEventId: string, subject: string, description: string, meetingId: number = null) {
    if (this.hasPermission && this.modules.has('native_calendar')) {
      if (meetingId) {
        description += `${this.tenantService.getName()} UID:MD-${meetingId}@`;
      }
      return this.calendar.updateEventInformation(
        existingEventId,
        subject,
        description,
      );
    } else {
      return Promise.resolve(null);
    }
  }

  public removeExistingEventNote(existingEventId: string, meetingId: number) {
    if (this.hasPermission && this.modules.has('native_calendar')) {
      return this.calendar.updateEventNote(
        existingEventId,
        'delete,' + `${this.tenantService.getName()} UID:MD-${meetingId}@`,
        'delete'
      );
    } else {
      return Promise.resolve(null);
    }
  }

  public deleteEvent(eventId: string) {
    if (this.hasPermission && this.modules.has('native_calendar')) {
      return this.calendar.deleteEventById(eventId);
    } else {
      return Promise.resolve(null);
    }
  }

  public newEvent(date: DateTime) {
    const now = DateTime.now();
    const from = date.set({
      hour: now.hour,
      minute: now.startOf('minute').plus({minutes: 15 - (now.minute % 15)}).minute,
      second: 0,
      millisecond: 0,
    });
    const till = from.plus({hour: 1});
    return this.calendars(true).then(calendars => {
      if (calendars.length > 0) {
        return this.storage.get('default-calendar').then(defaultCalendar => {
          if (!defaultCalendar) {
            this.storage.set('default-calendar', calendars[0]);
          }
          const selectedCalendar = defaultCalendar ? defaultCalendar.id : calendars[0].id;
          return this.createEventInteractively(from, till, selectedCalendar);
        });
      } else {
        return this.createEventInteractively(from, till);
      }
    });
  }

  getNativeAttendeeStatus(status) {
    if (this.platform.is('android')) {
      switch (status) {
        case 0:
          return '0';
        case 1:
        case 3:
        case 4:
          return '1';
        case 2:
          return '2';
      }
    } else if (this.platform.is('ios')) {
      switch (status) {
        case 0:
          return '0';
        case 1:
        case 3:
        case 4:
          return '2';
        case 2:
          return '3';
      }
    }
    return `${status}`;
  }

  getMdAttendeeStatus(status) {
    if (this.platform.is('android')) {
      switch (status) {
        case '0':
        case '3':
        case '4':
          return 0;
        case '1':
          return 1;
        case '2':
          return 2;
      }
    } else if (this.platform.is('ios')) {
      switch (status) {
        case '0':
        case '1':
        case '4':
          return 0;
        case '2':
          return 1;
        case '3':
          return 2;
      }
    }
    return parseInt(status, 10);
  }

  private combineCalendars(storeCalendars) {
    const combinedCalendars = [];
    return this.freshCalendars().then(newCalendars => {
      newCalendars.forEach(calendar => {
        const match = storeCalendars.filter(c => c.id === calendar.id)[0] ?? null;
        if (!match) {
          combinedCalendars.push(calendar);
        } else {
          combinedCalendars.push({
            id: calendar.id,
            name: calendar.name,
            enabled: match.enabled,
          });
        }
      });
      this.setCalendars(combinedCalendars);
      return combinedCalendars;
    });
  }
}
