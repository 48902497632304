import {Component, Input, ViewEncapsulation} from '@angular/core';
import {MeetingService} from '../../../../services/Meetings/MeetingService';
import {ToastService} from '../../../../services/General/ToastService';

@Component({
  selector: 'app-meeting-invite-options',
  templateUrl: './meeting-invite-options.component.html',
  styleUrls: ['./meeting-invite-options.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MeetingInviteOptionsComponent {
  @Input() host: boolean;
  @Input() meetingId: number;
  @Input() hideParticipants = false;
  @Input() hideSelf = false;

  constructor(private meetingService: MeetingService, private toaster: ToastService) {
  }

  updateHideParticipants() {
    this.meetingService.setHideParticipants(this.meetingId, this.hideParticipants).catch(err => {
      this.toaster.toast(err.errors ?? 'Something went wrong updating hide participants');
      this.hideParticipants = !this.hideParticipants;
    });
  }

  updateHideSelf() {
    this.meetingService.setHideSelf(this.meetingId, this.hideSelf).catch(err => {
      this.toaster.toast(err.errors ?? 'Something went wrong updating hide self');
      this.hideSelf = !this.hideSelf;
    });
  }

}
