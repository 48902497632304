import {Injectable} from '@angular/core';
import {StorageService} from '../Storage/StorageService';
import {AuthApiService} from './API/AuthApiService';
import {BehaviorSubject} from 'rxjs';
import {DateTime} from 'luxon';
import {StatusBarService} from '../General/StatusBarService';
import {ApiService} from '../General/ApiService';
import {SplashScreen} from '@capacitor/splash-screen';
import {NavController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(public api: AuthApiService, private storage: StorageService,
              private statusBar: StatusBarService, private apiService: ApiService,
              private nav: NavController) {
    this.api.setApiAuthService(this);
  }

  public login(username: string, password: string, step: string) {
    return this.api.login(username, password, step).then(data => {
      if (data.message) {
        return data;
      } else {
        this.apiService.setToken(data.access_token);
        this.storage.set('token', data.access_token);
        this.storage.set('refreshToken', data.refresh_token);
        this.storage.set('login_expire', DateTime.now().endOf('day').plus({hour: 2}).toMillis());
        this.loggedIn.next(true);
        return data;
      }
    });
  }

  public smsLogin(phone: string, step: string, code?: string) {
    return this.api.smsLogin(phone, step, code).then(data => {
      if (data.message) {
        return data;
      } else {
        this.apiService.setToken(data.access_token);
        this.storage.set('token', data.access_token);
        this.storage.set('refreshToken', data.refresh_token);
        this.storage.set('login_expire', DateTime.now().endOf('day').plus({hour: 2}).toMillis());
        this.loggedIn.next(true);
        return data;
      }
    });
  }

  public checkAuthMethod(username: string) {
    return this.api.checkAuthMethod(username);
  }

  public tokenLogin(token: string, pendingRegistrationToken: string = null) {
    return this.api.tokenLogin(token, pendingRegistrationToken).then(data => {
      this.loggedIn.next(true);
      this.storage.set('login_expire', DateTime.now().endOf('day').plus({hour: 2}).toMillis());
      return this.storage.set('token', data.token).then(() => true);
    });
  }

  public async isLoggedIn() {
    const expire = await this.storage.get('login_expire');
    if (!expire || expire < DateTime.now().toMillis()) {
      await this.logout('expired');
      await this.nav.navigateRoot(['/']);
      return null;
    }
    return await this.storage.get('token');
  }

  public logout(origin: string) {
    console.log(origin);
    this.loggedIn.next(false);
    this.api.api.token = null;
    this.api.api.tokenSet = false;
    SplashScreen.show();
    this.statusBar.setDarkColor();
    return this.storage.clear();
  }

  public authTransferTokenUrl(redirectUrl: string) {
    return this.api.authTransferTokenUrl(redirectUrl);
  }

  public checkedIn() {
    return this.api.checkedIn().then(response => {
      if (response) {
        return {
          roomId: parseInt(response.room_id, 10),
          time: DateTime.fromISO(response.created_at).toFormat('HH:mm')
        };
      }
      return null;
    });
  }

  public reverseOtpCode(step: string) {
    return this.api.reverseOtpCode(step);
  }

  public qrLogin(token) {
    try {
      return this.api.qrLoginStepOne(token).then(response =>
        this.api.qrLoginStepTwo(token, response.data));
    } catch (err) {
      throw err;
    }
  }

  public refreshLoginToken(refreshToken) {
    return this.api.refreshLoginToken(refreshToken);
  }

  public messagingReport(phone: string) {
    return this.api.messagingReport(phone);
  }

  public externalQrAuthentication(token: string) {
    return this.api.externalQrAuthentication(token);
  }
}
